import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { Testimonial, Testimonial2x } from "@thumbnails/Modules"
import { TestimonialLayout1, TestimonialLayout2 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Testimonial",
  component: "Testimonial",
  category: "people",
  dataPacks: ["TESTIMONIALS"],
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "imageBackground",
          hideable: true,
          helptext: "Recommended minimum image size: 288x288",
        },
        {
          type: "ReferenceField",
          sources: [{ structuredData: "TESTIMONIALS" }],
          key: "data",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Auto Height",
          key: "autoHeight",
          type: "RadioGroup",
          options: [
            { name: "yes", value: "true", title: "Yes" },
            { name: "no", value: "false", title: "No" },
          ],
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: [
            {
              value: "L001",
              img: TestimonialLayout1,
            },
            {
              value: "L002",
              img: TestimonialLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "Testimonial",
    imageBackground: {
      component: "Image",
    },
    theme: "inverse",
    layout: "L001",
    autoHeight: false,
    hasDistributorData: true,
    data: {
      mode: "auto",
      order: "recent",
      sources: [
        {
          structuredData: "TESTIMONIALS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 6,
    },
  },
  thumbnails: {
    "1x": Testimonial,
    "2x": Testimonial2x,
  },
}
