// DEFAULT VALUES
const getDefaultValues = () => ({
  title: "Study Plan",
  years: [getDefaultYear("Year 1"), getDefaultYear("Year 2")],
  CCS: [
    getCCS("Credit Title 1"),
    getCCS("Credit Title 2"),
    getCCS("Credit Title 3"),
    getCCS("Credit Title 4"),
  ],
})

const getDefaultYear = yearTitle => ({
  yearTitle,
  yearSubtitle: "",
  yearDescription: "",
  yearVideo: "",
  yearImg: "",
  semesters: [getDefaultSemester("Semester 1")],
})

const getDefaultSemester = semTitle => {
  return {
    semTitle,
    subjects: [getDefaultSubject("Subject 1")],
  }
}

const getDefaultSubject = subjTitle => ({
  subjTitle,
  subjType: "",
  subjCredits: "",
  subjTeachers: [],
  documentConditional: "url",
  subjDocumentFile: "",
  subjDocumentUrl: "",
})

const getCCS = CCSTitle => ({
  CCSTitle,
  CCSNumber: "",
})

// STRUCTURED DATA
export const STUDYPLANS = {
  title: "StudyPlans",
  dataPacks: ["STUDYPLANS"],
  local: false,
  taxonomy: false,
  fromPage: false,
  translate: true,
  schema: {
    fields: [
      {
        key: "title",
        title: "Title",
        type: "TextField",
        mandatory: true,
        searchable: false,
        humanReadable: true,
      },
      // YEARS
      {
        type: "ArrayFieldGroup",
        title: "",
        key: "years",
        name: "Year",
        fields: [
          {
            title: "Title",
            type: "TextField",
            key: "yearTitle",
            mandatory: "true",
            isTitle: true,
            humanReadable: true,
          },
          {
            title: "Title Description",
            type: "TextField",
            key: "yearSubtitle",
            humanReadable: true,
          },
          {
            title: "Year Description",
            type: "RichText",
            html: true,
            key: "yearDescription",
            humanReadable: true,
          },
          {
            key: "divider",
            title: "",
            type: "FieldsDivider",
            data: {
              title: "Year Media",
              text: "You can add a video or image to the year. if you add both, image will be the thumbnail of the video.",
            },
          },
          {
            key: "yearVideo",
            title: "Year Video URL",
            type: "TextField",
          },
          {
            type: "UniqueCheck",
            key: "automaticSubtitles",
            options: [
              {
                title: "Add automatic subtitles from Youtube",
              },
            ],
          },
          {
            key: "yearImg",
            title: "Year Image",
            type: "ImageField",
          },
          // SEMESTERS
          {
            type: "ArrayFieldGroup",
            title: "SEMESTERS",
            key: "semesters",
            name: "Semester",
            mandatory: "true",
            fields: [
              {
                title: "Title",
                type: "TextField",
                key: "semTitle",
                mandatory: "true",
                isTitle: true,
                humanReadable: true,
              },
              // SUBJECTS
              {
                type: "ArrayFieldGroup",
                title: "Subjects",
                key: "subjects",
                name: "Subject",
                mandatory: "true",
                fields: [
                  {
                    title: "Title",
                    type: "TextField",
                    key: "subjTitle",
                    mandatory: "true",
                    isTitle: true,
                    humanReadable: true,
                  },
                  {
                    title: "Type",
                    type: "TextField",
                    key: "subjType",
                    mandatory: "true",
                    humanReadable: true,
                  },
                  {
                    title: "Credits",
                    type: "NumberField",
                    key: "subjCredits",
                    mandatory: "true",
                  },
                  {
                    type: "ReferenceField",
                    sources: [{ structuredData: "TEACHERS" }],
                    key: "subjTeachers",
                    title: "Teachers",
                    selectionType: ["manual"],
                    maxItems: 4,
                  },
                  {
                    title: "Document",
                    type: "ConditionalField",
                    key: "documentConditional",
                    options: [
                      {
                        value: "file",
                        title: "Upload File",
                        name: "file",
                      },
                      {
                        value: "url",
                        title: "Url",
                        name: "url",
                      },
                    ],
                    fields: [
                      {
                        title: "Document File",
                        type: "FileField",
                        key: "subjDocumentFile",
                        condition: "file",
                      },
                      {
                        title: "Document URL",
                        type: "TextField",
                        key: "subjDocumentUrl",
                        placeholder: "https://",
                        condition: "url",
                      },
                    ],
                  },
                ],
                arrayType: "drowpDown",
                divider: {
                  title: "SUBJECT DATA",
                  text: "You can add one or more subjects to this Semester",
                },
              },
            ],
            arrayType: "drowpDown",
            divider: {
              title: "",
              text: "",
            },
          },
        ],
        arrayType: "drowpDown",
        divider: {
          title: "STUDY PLAN DATA",
          text: "You can add one or more years to this Study Plan",
        },
      },
      // COURSE CREDITS SUMMARY
      {
        type: "ArrayFieldGroup",
        title: "",
        key: "CCS",
        name: "Credit",
        fields: [
          {
            title: "Credits Title",
            type: "TextField",
            key: "CCSTitle",
            humanReadable: true,
          },
          {
            title: "Credits Number",
            type: "NumberField",
            key: "CCSNumber",
          },
        ],
        arrayType: "inline",
        divider: {
          title: "COURSE CREDITS SUMMARY",
          text: "You can create a table with course credits summary",
        },
      },
      // TOTAL CREDITS TITLE
      {
        key: "divider",
        title: "",
        type: "FieldsDivider",
        data: {
          title: "",
          text: "",
        },
      },
      {
        title: "Total Credits Title",
        type: "TextField",
        key: "totalCreditsTitle",
        humanReadable: true,
      },
      {
        title: "Total Credits Number",
        type: "NumberField",
        key: "totalCreditsNumber",
      },
      // ADVISER MODULE
      {
        key: "divider",
        title: "",
        type: "FieldsDivider",
        data: {
          title: "Adviser Module",
          text: "You can add an Adviser Module",
        },
      },
      {
        title: "Adviser Module Title",
        type: "TextField",
        key: "adTitle",
        humanReadable: true,
      },
      {
        title: "Adviser Module Detail",
        type: "TextField",
        key: "adDetail",
        humanReadable: true,
      },
      {
        title: "Adviser Module Link",
        type: "UrlField",
        advanced: true,
        key: "adUrl",
      },
    ],
  },
  clone: null,
  defaultValues: getDefaultValues(),
}
