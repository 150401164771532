import { getThemeOptions } from "@helpers/schemas"
import { ProjectDistributor, ProjectDistributor2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Project Distributor",
  component: "ProjectDistributor",
  category: "studies",
  dataPacks: ["PROJECTS"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramsList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          type: "ReferenceField",
          sources: [{ structuredData: "PROJECTS" }],
          key: "data",
          mandatory: true,
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
          humanReadable: true,
        },
        {
          title: "Select option link",
          type: "RadioGroup",
          key: "optionLink",
          mandatory: true,
          required: true,
          options: [
            {
              name: "Link to detail",
              value: "linkToDetail",
              title: "Link to detail",
            },
            {
              name: "External link project",
              value: "linkToExternal",
              title: "External link project",
            },
          ],
        },
        {
          title: "Select project data",
          type: "CheckGroup",
          key: "projectData",
          hideable: true,
          options: [
            {
              value: "showAuthor",
              title: "Show author",
              name: "showAuthor",
            },
            {
              value: "showSubject",
              title: "Show subject",
              name: "showSubject",
            },
            {
              value: "showDate",
              title: "Show date",
              name: "showDate",
            },
            {
              value: "showProgram",
              title: "Show program",
              name: "showProgram",
            },
            {
              value: "showTutor",
              title: "Show tutor",
              name: "showTutor",
            },
            {
              value: "showProjectLink",
              title: "Show project link",
              name: "showProjectLink",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "ProjectDistributor",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    title: {
      content: "Lorem ipsum",
      tag: "h4",
    },
    subtitle: null,
    detail: null,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "PROJECTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 10,
      preferenceLanguage: false,
    },
    cardTitleTag: "h3",
    hasDistributorData: true,
    noResultsText:
      "Sorry, no results were found.Please try a different search.",
    optionLink: "linkToDetail",
    projectData:
      "showDate,showSubject,showAuthor,showProgram,showTutor,showProjectLink",
    groupingLink: {
      component: "Link",
      text: "Discover more projects",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": ProjectDistributor,
    "2x": ProjectDistributor2x,
  },
}
