import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { AccordionElement, AccordionElement2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Accordion Element",
  component: "AccordionElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Icon",
          type: "ImageField",
          key: "icon",
          hideable: true,
        },
        {
          title: "Component",
          type: "ComponentArray",
          mandatory: true,
          whiteList: [
            "BasicCard",
            "BasicContent",
            "CardCollection",
            "IntroTextCard",
            "ScholarshipsDistributor",
            "Wysiwyg",
          ],
          key: "componentModules",
        },
      ],
    },
  ],
  default: {
    component: "AccordionElement",
    title: { content: "Title", tag: "h3" },
    icon: null,
    titleSize: "xl",
    componentModules: [
      {
        component: "BasicContent",
        title: {
          content: "Default title",
          tag: "h1",
        },
        subtitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
        primaryLink: {
          component: "LinkModal",
          style: "primary",
          link: {
            text: "Link",
            linkType: "url",
            url: {
              linkToURL: null,
              newTab: false,
              noFollow: false,
            },
            modal: {
              requestInfo: {
                component: "RequestInfo",
              },
              floatingCTAMail: {
                component: "FloatingCTAMail",
              },
              floatingCTALightbox: {
                component: "FloatingCTALightbox",
              },
              floatingCTAVideo: {
                component: "FloatingCTAVideo",
              },
            },
          },
        },
        secondaryLink: {
          component: "LinkModal",
        },
        additionalContent: {
          image: {
            component: "LinkableImage",
            ...DEFAULT_IMAGE_COMPONENT,
            veil: 0,
          },
          video: {
            component: "Video",
          },
        },
        theme: "defaultAlt",
        layout: "L001",
        moduleOrder: null,
      },
    ],
  },
  thumbnails: {
    "1x": AccordionElement,
    "2x": AccordionElement2x,
  },
}
