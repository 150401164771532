import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { BlogDistributor, BlogDistributor2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Blog Distributor",
  component: "BlogDistributor",
  category: "interactive",
  dataPacks: ["BLOG"],
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          type: "ReferenceField",
          sources: [
            { structuredData: "BLOGS" },
            { structuredData: "INSIGHTS" },
          ],
          key: "data",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "BlogDistributor",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    subtitle: null,
    detail: null,
    data: {
      mode: "auto",
      order: "recent",
      sources: [
        {
          structuredData: "BLOGS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 20,
      preferenceLanguage: false,
    },
    hasDistributorData: true,
    noResultsText: "Sorry, no results were found.",
    cardTitleTag: "h3",
    groupingLink: {
      component: "Link",
      text: "Discover more blogs",
      style: "secondary",
      url: {
        href: "https://drivinginnovation.ie.edu/",
        linkToURL: null,
        linkTo: null,
        newTab: true,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": BlogDistributor,
    "2x": BlogDistributor2x,
  },
}
