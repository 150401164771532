export function youtubeThumbnailFromUrl(url, size = "max") {
  const id = idFromYoutubeUrl(url)
  const sizes = {
    max: "maxresdefault.jpg",
    standard: "sddefault.jpg",
    medium: "mqdefault.jpg",
    hiq: "hqdefault.jpg",
    default: "default.jpg",
  }
  const thumbnailUrl = `https://img.youtube.com/vi/${id}/${sizes[size]}`
  return thumbnailUrl
}

export async function vimeoThumbnailFromUrl(url) {
  const response = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
  const responseJson = await response.json()
  const thumbnailUrl = responseJson.thumbnail_url
  return thumbnailUrl
}

export function idFromYoutubeUrl(url) {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url?.match(regExp)
  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return null
  }
}

export function idFromVimeoUrl(url) {
  const regExp =
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+[\/]?[A-Za-z0-9]*)(?:[a-zA-Z0-9_\-]+)?/i
  const match = url?.match(regExp)
  if (match && match[1]) {
    return match[1].replace("/", "?h=")
  } else {
    return null
  }
}

export function idFromSpreakerUrl(iframePodcast) {
  var episodeIdMatch = iframePodcast?.match(/episode_id=(\d+)/)
  if (episodeIdMatch && episodeIdMatch[1]) {
    return episodeIdMatch[1]
  } else {
    return null
  }
}

export async function spreakerThumbnailFromUrl(iframePodcast) {
  const id = idFromSpreakerUrl(iframePodcast)
  const response = await fetch(`https://api.spreaker.com/v2/episodes/${id}`)
  const responseJson = await response.json()
  const thumbnailSpreaker = responseJson.response.episode.image_original_url
  return thumbnailSpreaker
}

export function srcFromSpreakerUrl(richtPodcast) {
  const regExp = /<iframe.*?src=[""](.*?)[""]/
  const match = richtPodcast?.match(regExp)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}
