import { getThemeOptions } from "@helpers/schemas"
import { StudyPlan, StudyPlan2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "StudyPlan",
  component: "StudyPlan",
  category: "studies",
  dataPacks: ["STUDYPLANS"],
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Study Plan",
          type: "ReferenceField",
          sources: [{ structuredData: "STUDYPLANS" }],
          key: "data",
          selectionType: ["manual"],
          mandatory: true,
          required: true,
        },
        {
          title: "Show Professors",
          key: "showProfessors",
          type: "RadioGroup",
          options: [
            { name: "yes", value: "true", title: "Yes" },
            { name: "no", value: "false", title: "No" },
          ],
        },
        {
          title: "Show Year Description",
          key: "showYearDescription",
          type: "RadioGroup",
          options: [
            { name: "yes", value: "true", title: "Yes" },
            { name: "no", value: "false", title: "No" },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Title Size",
          type: "RadioGroup",
          key: "titleSize",
          helptext: "",
          options: [
            {
              name: "Text XL",
              value: "large",
              title: "Text XL",
            },
            {
              name: "Text S",
              value: "small",
              title: "Text S",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "StudyPlan",
    anchorID: null,
    theme: "defaultAlt",
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    titleSize: "large",
    subtitle: null,
    detail: null,
    data: {
      mode: "manual",
      order: "recent",
      sources: [
        {
          structuredData: "STUDYPLANS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 1,
      fixed: [],
    },
    hasDistributorData: true,
    showProfessors: true,
    showYearDescription: true,
  },
  thumbnails: {
    "1x": StudyPlan,
    "2x": StudyPlan2x,
  },
}
