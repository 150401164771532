import TopZIndex from "@constants/TopZIndex"
import { NavigationBanner } from "@components"
import { parseSocials, searchResultsPage } from "@helpers"
import { useI18n, usePage, useRenderer, useSite } from "@hooks"
import { Box } from "@sqymagma/elements"
import { createPageTheme } from "@themes/utils"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import Headroom from "react-headroom"
import { ThemeProvider } from "styled-components"
import MenuContent from "./MenuContent"
import TopNav from "./TopNav"

const Header = ({
  mainMenu,
  topMenu,
  school,
  primaryLink,
  secondaryLink,
  showTopNavigation,
  topNavigationContent,
  orderSocialIcons,
  socialMediaOrder,
  navigationBanner,
  navigationBannerIcon,
  navigationBannerText,
  navigationBannerLink,
  navigationBannerBGColor,
  sticky,
  anchorID,
  moduleTheme,
}) => {
  const [menuBg, setMenuBg] = useState({ open: false, element: null })
  const { firstModule } = usePage(null)
  const [unPin, setUnpin] = useState(false)
  const [unFix, setUnfix] = useState(false)
  const whiteListModules = [
    "HeroCarousel",
    "HeroClaim",
    "HeroImage",
    "HeroLanding",
    "HeroMosaic",
    "HeroVideo",
    "HeroProgram",
    "HeroProgramVideo",
  ]
  const heroInHeroSection = whiteListModules.includes(firstModule)
  const variant = heroInHeroSection ? "transparent" : "solid"
  const logo = heroInHeroSection ? "logoLight" : "logo"

  const { socials } = useSite()
  const { pageLanguages, languageId, theme, headerTheme } = usePage()
  const { getTranslation } = useI18n()

  const showSocialMedia = topNavigationContent?.includes("showSocialMedia")
  const showBot = topNavigationContent?.includes("showBot")

  const botUrl = searchResultsPage
  const { isSSG, isEditor } = useRenderer()
  const showBotIcon = showBot && (!isSSG || botUrl)

  const socialsArray = parseSocials(socials)

  const [stickyCtx, setSticky] = useState({
    firstScroll: false,
  })

  const CustomFirstScroll = () => {
    setSticky(p => ({ ...p, firstScroll: true }))
    window.removeEventListener("scroll", CustomFirstScroll)
  }

  const SetFirstScroll = () => {
    if (window !== undefined) {
      if (!stickyCtx.firstScroll) {
        window.addEventListener("scroll", CustomFirstScroll)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      SetFirstScroll()
    }, 1000)
  }, [])

  // This condition is to prevent the sticky if !firstScroll,
  // To prevent the fixed until the first scroll
  const StickyCondition = sticky && stickyCtx.firstScroll

  const customSetMenuBg = (bool, idx) => {
    setMenuBg(p => ({
      open:
        mainMenu?.elements[idx]?.children?.length > 0 && bool === true
          ? true
          : false,
      element: mainMenu?.elements[idx]?.children?.length > 0 ? idx : p.element,
    }))
  }

  const pageTheme =
    headerTheme || theme ? createPageTheme(headerTheme || theme) : null

  // Social media order
  function orderSocialsArray(socialMediaOrder, socialsArray) {
    let newsOrderSocialMedia = []

    socialMediaOrder?.forEach(icon => {
      let findSocial = socialsArray?.find(
        social => social.icon.toLowerCase() === icon.toLowerCase()
      )

      if (findSocial) {
        newsOrderSocialMedia?.push(findSocial)
      }
    })

    return newsOrderSocialMedia
  }

  const HeaderContent = (
    <header>
      {navigationBanner && (
        <Box position="relative" zIndex={9}>
          <NavigationBanner
            icon={navigationBannerIcon}
            text={navigationBannerText}
            link={navigationBannerLink}
            bgColor={navigationBannerBGColor}
          />
        </Box>
      )}

      {showTopNavigation && (
        <Box
          position="relative"
          zIndex={9}
          data-testid="topNavigation"
          className="top-navigation"
        >
          <TopNav
            socials={
              showSocialMedia && orderSocialIcons
                ? orderSocialsArray(socialMediaOrder, socialsArray)
                : showSocialMedia && !orderSocialIcons && socialsArray
            }
            externalLinks={topMenu?.elements}
            showBot={showBot}
            pageLanguages={pageLanguages}
            languageId={languageId}
            languageNames={getTranslation("languageNames")}
          />
        </Box>
      )}

      <AnimatePresence>
        {menuBg.open && (
          <motion.div
            initial="closed"
            animate={menuBg.open ? "open" : "closed"}
            exit="closed"
            variants={{
              open: {
                transition: { delay: 0, duration: 0.6 },
                opacity: 1,
              },
              closed: {
                transition: { duration: 0 },
                opacity: 0,
              },
            }}
            style={{
              position: "fixed",
              zIndex: 3,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}
      </AnimatePresence>
      <Headroom
        pinStart={sticky && 100}
        onUnpin={
          sticky &&
          (() => {
            setUnpin(true)
            setUnfix(false)
            setMenuBg(p => ({ open: false, ...p }))
          })
        }
        disable={!StickyCondition}
        onUnfix={
          sticky &&
          (() => {
            setUnpin(false)
            setUnfix(true)
          })
        }
        style={{ zIndex: unPin ? TopZIndex.header.main : 4 }}
      >
        <MenuContent
          sticky={sticky ? true : false}
          customSetMenuBg={customSetMenuBg}
          menuBg={menuBg}
          variant={variant}
          logo={logo}
          unPin={unPin}
          unFix={unFix}
          anchorID={anchorID}
          school={school}
          showBotIcon={showBotIcon}
          mainMenu={mainMenu}
          primaryLink={primaryLink}
          secondaryLink={secondaryLink}
          pageLanguages={pageLanguages}
          getTranslation={getTranslation}
          languageId={languageId}
          showSocialMedia={showSocialMedia}
          topMenu={topMenu}
          botUrl={botUrl}
          socialsArray={
            showSocialMedia && orderSocialIcons
              ? orderSocialsArray(socialMediaOrder, socialsArray)
              : orderSocialIcons && !orderSocialIcons && socialsArray
          }
        />
      </Headroom>
    </header>
  )

  return pageTheme && moduleTheme ? (
    <ThemeProvider theme={pageTheme.subthemes[moduleTheme]}>
      {HeaderContent}
    </ThemeProvider>
  ) : pageTheme ? (
    <ThemeProvider theme={pageTheme}>{HeaderContent}</ThemeProvider>
  ) : (
    HeaderContent
  )
}

export default Header
