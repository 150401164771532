import { allModulesWhitelist } from "@helpers/schemas"
import { GeneralInfoDetail, GeneralInfoDetail2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "General Info Detail",
  component: "GeneralInfoDetail",
  dataPacks: ["GENERALINFO"],
  type: { label: "GeneralInfo", value: "generalinfo", mode: "detail" },
  content: [
    {
      key: "gidCategories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "regions",
          placeholder: "Region",
          source: "REGION",
        },
        {
          key: "centers",
          placeholder: "Centers & Departments",
          source: "CENTERS",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
      ],
    },
    {
      key: "gidHeroSection",
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["HeroText", "HeroQuote"],
    },
    {
      key: "gidName",
      title: "Name",
      type: "TextField",
      mandatory: true,
      helptext: "Shown on the distributor card",
      humanReadable: true,
    },
    {
      key: "gidImage",
      title: "Image",
      type: "ImageField",
      mandatory: true,
      helptext: "Shown on the distributor card",
    },
    {
      key: "gidAbout",
      title: "About",
      type: "RichText",
      html: true,
      humanReadable: true,
    },
    {
      key: "gidQuickFacts",
      title: "Facts",
      type: "ComponentArray",
      maxItems: null,
      whiteList: ["QuickFact"],
    },
    {
      key: "gidSocialShare",
      title: "Social Share",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["SocialShare"],
    },
    {
      key: "gidMainContent",
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
    },
    {
      title: "Show pagination",
      type: "RadioGroup",
      key: "gidShowPagination",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      key: "gidPagImage",
      title: "Image",
      type: "ImageField",
      helptext: "Shown on pagination",
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "gidRelatedContent",
      whiteList: allModulesWhitelist,
    },
  ],
  default: {
    type: "template",
    templateType: "GeneralInfoDetail",
    gidHeroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    gidName: "",
    gidImage: "",
    gidAbout:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    gidQuickFacts: {
      component: "Section",
      name: "More Quick Facts",
      modules: [],
      sectionPosition: 2,
    },
    gidSocialShare: {
      component: "Section",
      name: "Show Social Share",
      modules: [],
      sectionPosition: 3,
    },
    gidMainContent: {
      component: "Section",
      name: "Main Content",
      modules: [
        {
          component: "Wysiwyg",
          theme: "default",
          showHero: false,
          image: null,
          titleOption: false,
          titleAlign: "left",
          title: "",
          subtitle: "",
          quote: "",
          contentTitle: "",
          introduction: "",
          featuredVisualContent: {
            image: {
              component: "LinkableImage",
            },
            video: {
              component: "Video",
            },
          },
          content: `<p>Massa curabitur vestibulum amet vehicula semper montes mattis non pulvinar odio eget, suscipit penatibus dictumst interdum ipsum platea ultrices tempus fames quis. Pretium auctor fames commodo hac sollicitudin maecenas dis varius tellus tristique, vehicula nisl adipiscing odio primis ante tempus venenatis. Phasellus cursus a gravida ante elementum faucibus lectus interdum tortor tristique, justo nulla aenean hendrerit malesuada id molestie tempus vestibulum, sociis purus blandit facilisi varius sem tellus mollis mus. Erat aptent interdum fusce feugiat blandit vestibulum vivamus nostra cursus imperdiet aliquet tempor, felis mi nunc vitae quisque bibendum ultricies lacinia integer suscipit augue habitant, tempus consectetur tellus penatibus pharetra sagittis dis facilisis montes sapien sem. Conubia placerat orci at molestie cum quam, phasellus maecenas fames netus lacinia, facilisi ligula iaculis metus velit semper, malesuada per nostra dapibus.</p>`,
          link: {
            component: "Link",
          },
          theme: "defaultAlt",
        },
      ],
      sectionPosition: 4,
    },
    gidShowPagination: true,
    gidRelatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 5,
    },
    hasDistributorData: true,
    data: {
      quantity: 1,
      mode: "navigation",
      order: "recent-asc",
    },
  },
  thumbnails: {
    "1x": GeneralInfoDetail,
    "2x": GeneralInfoDetail2x,
  },
}
