import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { InsightsDistributor, InsightsDistributor2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Insights Distributor",
  component: "InsightsDistributor",
  category: "articlesAndEvents",
  dataPacks: ["INSIGHTS"],
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
          humanReadable: true,
        },
        {
          type: "ReferenceField",
          sources: [{ structuredData: "INSIGHTS" }],
          key: "data",
          mandatory: true,
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
          humanReadable: true,
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Secondary Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link2",
          hideable: true,
        },
        {
          title: "Main Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "InsightsDistributor",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { tag: "h2", content: "Lorem Ipsum" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    detail: null,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "INSIGHTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "insightDate-DESC",
      quantity: 3,
      preferenceLanguage: false,
    },
    hasDistributorData: true,
    noResultsText: "Sorry, no results were found.",
    cardTitleTag: "h3",
    link2: {
      component: "Link",
      text: "Go to IE Insights",
      style: "secondary",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        external: true,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    link: {
      component: "Link",
      text: "Subscribe Now",
      style: "primary",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        external: true,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
  },
  thumbnails: {
    "1x": InsightsDistributor,
    "2x": InsightsDistributor2x,
  },
}
