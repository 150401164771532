import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { allModulesWhitelist } from "@helpers/schemas"
import { TeacherDetail, TeacherDetail2x } from "@thumbnails/Templates"

// Defaults from modules
import Wysiwyg from "@schemas/modules/Wysiwyg"
import HeroText from "@schemas/modules/HeroText"

export default {
  displayName: "Teacher Detail",
  component: "TeacherDetail",
  dataPacks: ["TEACHERS"],
  type: { label: "Teachers", value: "teachers", mode: "detail" },
  schemaType: "template",
  content: [
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "genders",
          placeholder: "Gender",
          source: "GENDER",
        },
        {
          key: "regions",
          placeholder: "Region",
          source: "REGION",
        },
        {
          key: "academicArea",
          placeholder: "Academic area",
          source: "ACADEMIC_AREA",
        },
        {
          key: "facultyCategory",
          placeholder: "Faculty category",
          source: "FACULTY_CATEGORY",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
      ],
    },
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
    },
    {
      title: "Image",
      key: "image",
      mandatory: true,
      type: "ImageField",
      helptext: "Shown on the distributor card",
    },
    {
      title: "Background Image",
      key: "imageBackground",
      type: "ImageField",
      helptext:
        "Only shown on the distributor card. Recommended minimum image size: 120x120",
    },
    {
      title: "Name",
      key: "name",
      mandatory: true,
      type: "TextField",
      helptext: "Shown on the distributor card",
    },
    {
      title: "Last Name",
      key: "lastName",
      mandatory: true,
      type: "TextField",
      helptext: "Shown on the distributor card",
    },
    {
      title: "Position",
      key: "position",
      type: "TextField",
      helptext: "Shown on the distributor card",
      humanReadable: true,
    },
    {
      title: "Contact",
      key: "contact",
      hideable: true,
      type: "TextField",
    },
    {
      title: "CV",
      key: "cv",
      hideable: true,
      type: "UrlField",
      advanced: true,
    },
    {
      title: "Linkedin",
      key: "linkedin",
      hideable: true,
      type: "UrlField",
      advanced: true,
    },
    {
      title: "Personal Web",
      key: "personalWeb",
      hideable: true,
      type: "UrlField",
      advanced: true,
    },
    {
      title: "Nationality",
      key: "nationality",
      hideable: true,
      type: "TextField",
      humanReadable: true,
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      key: "mainContent",
      whiteList: allModulesWhitelist,
    },
    {
      title: "Show pagination",
      type: "RadioGroup",
      key: "showPagination",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "TeacherDetail",
    data: {
      quantity: 1,
      mode: "navigation",
      order: "recent-asc",
    },
    hasDistributorData: true,
    showPagination: true,
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ ...HeroText.default }],
      sectionPosition: 1,
    },
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
    imageBackground: {
      ...DEFAULT_IMAGE_FIELD,
    },
    name: "Name",
    lastName: "Last Name",
    position: "Position",
    contact: "email@domain.com",
    cv: {
      external: true,
      href: "",
      icon: "",
      label: "View CV",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    linkedin: {
      external: true,
      href: "",
      icon: "",
      label: "View Profile",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    personalWeb: {
      external: true,
      href: "",
      icon: "",
      label: "Personal Web",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    nationality: "nationality",
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [{ ...Wysiwyg.default }],
      sectionPosition: 2,
    },
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 3,
    },
  },
  thumbnails: {
    "1x": TeacherDetail,
    "2x": TeacherDetail2x,
  },
}
