import { useState } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { LinkWrapper } from "@components"
import { Stack, Text, Flex } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import TopZIndex from "@constants/TopZIndex"
import React from "react"

const SubMenuLabel = styled(LinkWrapper)`
  position: relative;
  z-index: ${p => (p.desktop ? TopZIndex.header.recursive : "1")};
`

const ListAnchor = styled(LinkWrapper)`
  width: 100%;
  svg {
    display: none;
  }
`

const SubMenuList = styled(motion.ul)`
  overflow: hidden;
  width: auto;
  height: auto;
  margin-left: ${p => (p.desktop ? null : theme("spacing.xxxs"))};
  position: ${p => (p.desktop ? "absolute" : "static")};
  z-index: ${p => (p.desktop ? TopZIndex.header.subMenu : null)};
  top: ${p => (p.desktop ? "-20px" : null)};
  left: ${p => (p.desktop ? "-25px" : null)};
  background: ${p => (p.desktop ? theme("colors.inverse02") : "transparent")};
  box-shadow: ${p =>
    p.desktop ? "0px 4px 12px 0px rgba(0, 0, 0, 0.15)" : null};
  padding: ${p => (p.desktop ? 0 : null)};
  padding-top: ${p => (p.desktop ? "40px" : null)};
`

const ListItem = styled.li`
  cursor: pointer;
  list-style: none;
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${p => (p.desktop ? "auto" : theme("spacing.xxs"))};
  padding: ${p => (p.desktop ? theme("spacing.s") : "auto")};
  height: ${p => (p.desktop ? "40px" : "auto")};
  border-bottom: ${p => (p.desktop ? "1px solid #E8E8EA" : null)};
  &:last-child {
    border: none;
  }
`

const SubMenuWrapper = styled(motion.li)`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  color: ${p => p.theme.colors.text01};
  svg {
    position: relative;
    transition: transform 0.25s ease-out;
    ${p => p.open && "transform: rotate(-180deg);"};
    path {
      fill: ${p => p.theme.colors.text01};
    }
  }
  &:hover {
    svg {
      transform: ${p => (p.desktop || p.open ? "rotate(-180deg)" : "none")};
      z-index: ${p => (p.desktop ? TopZIndex.header.subMenuWrapper : "1")};
      path {
        fill: ${p =>
          p.desktop ? theme("colors.inverse01") : theme("colors.text01")};
      }
    }
    ${SubMenuLabel} {
      span {
        color: ${p => (p.desktop ? "#888A8C" : theme("colors.text01"))};
      }
    }
  }
`

const RecursiveMenu = ({ externalLinks, searchLink, desktop }) => {
  const [listOpen, setListOpen] = useState(-1)

  const toggleList = idx => {
    if (listOpen === -1 || idx !== listOpen) {
      setListOpen(idx)
    } else {
      setListOpen(-1)
    }
  }

  return (
    <Stack
      hs={desktop ? "m" : null}
      vs={desktop ? null : "xxs"}
      flexDirection={desktop ? null : "column"}
      alignItems={desktop ? "center" : null}
      as="ul"
    >
      {externalLinks &&
        externalLinks?.map((link, idx) =>
          link.children?.length ? (
            <SubMenuWrapper
              key={idx}
              desktop={desktop}
              open={listOpen === idx}
              onHoverStart={() => desktop && setListOpen(idx)}
              onHoverEnd={() => desktop && setListOpen(-1)}
              data-testid="subMenu"
            >
              <Flex alignItems="center">
                <SubMenuLabel
                  desktop={desktop}
                  rel="noopener"
                  aria-label={link.label}
                  title={link.label}
                  anchor={link.url?.anchor}
                  href={link.url?.href}
                  linkToURL={link.url?.linkToURL}
                  newTab={link.url?.newTab}
                  noFollow={link.url?.noFollow}
                  onKeyDown={event => {
                    if (event.key === "Enter" && desktop) {
                      listOpen === idx ? setListOpen(-1) : setListOpen(idx)
                    }
                  }}
                >
                  <Text textStyle="tinyxs" color="text01" caps>
                    {link.label}
                  </Text>
                </SubMenuLabel>
                <Icons
                  name="dropDown"
                  onClick={() => !desktop && toggleList(idx)}
                  height="10px"
                  ml="7px"
                  mt="4px"
                  data-testid="dropDown"
                  aria-hidden={true}
                />
              </Flex>
              <AnimatePresence>
                {listOpen === idx && (
                  <SubMenuList
                    desktop={desktop}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {link?.children &&
                      link?.children.map((subItem, subIdx) => {
                        return (
                          <ListItem key={subIdx} desktop={desktop}>
                            <ListAnchor
                              title={subItem.label}
                              anchor={subItem.url?.anchor}
                              href={subItem.url?.href}
                              linkToURL={subItem.url?.linkToURL}
                              newTab={subItem.url?.newTab}
                              noFollow={subItem.url?.noFollow}
                            >
                              <Text
                                caps
                                textStyle="tinys"
                                color={desktop ? "#888A8C" : "text01"}
                              >
                                {subItem.label}
                              </Text>
                            </ListAnchor>
                          </ListItem>
                        )
                      })}
                  </SubMenuList>
                )}
              </AnimatePresence>
            </SubMenuWrapper>
          ) : (
            <li key={idx}>
              <LinkWrapper
                rel="noopener"
                aria-label={link?.url?.description || link?.label}
                title={link?.label}
                linkToURL={link?.url?.linkToURL}
                href={link?.url?.href}
                newTab={link?.url?.newTab}
                noFollow={link?.url?.noFollow}
              >
                <Text textStyle="tinyxs" caps color="text01">
                  {link?.label}
                </Text>
              </LinkWrapper>
            </li>
          )
        )}
      {searchLink && (
        <LinkWrapper
          rel="noopener"
          aria-label={searchLink.label}
          {...searchLink?.url}
        >
          <Text textStyle="tinyxs" caps color="text01">
            {searchLink?.label}
          </Text>
        </LinkWrapper>
      )}
    </Stack>
  )
}

export default RecursiveMenu
