import React, { useState } from "react"
import { Box, Flex, Text } from "@sqymagma/elements"
import Icons from "@icons"
import { themeGet as theme } from "@styled-system/theme-get"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { LinkWrapper } from "@components"
import Portal from "@elements/Portal"
import { useI18n } from "@hooks"

const SubMenuContainer = styled(Box)`
  display: block;
  ${theme("mq.xl")} {
    display: none;
  }
`
const SubLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`

const SubNavList = styled.ul``

const AuxText = styled(Box)`
  margin-bottom: ${theme("spacing.m")};
`

const SubNavItem = styled.li`
  color: ${theme("colors.inverse01")};
  padding-bottom: ${theme("spacing.xxs")};
  &:last-child {
    ${AuxText} {
      margin-bottom: 0;
    }
  }
`
const SubLinkItem = styled(Text)`
  cursor: pointer;
  font-weight: 500;
  line-height: 18px;
  ${theme("mq.m")} {
    font-size: 16px;
    line-height: 18px;
  }
`

const SubItemLinkWrapper = styled(LinkWrapper)`
  opacity: ${p => (p.active ? 0.5 : 1)};
`

const SubMenuWrapper = styled(motion.div)`
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 560px;
  height: 100vh;
  background-color: ${theme("colors.ui01")};
  padding: ${theme("spacing.s")};
  ${theme("mq.m")} {
    padding: 0 ${theme("spacing.xxl")};
  }
  ${theme("mq.xl")} {
    display: none;
  }
`

const BackToMain = styled(Box)`
  cursor: pointer;
  margin-left: -5px;
`

const SubMenu = ({ menuItem, isActiveLink }) => {
  const [subOpen, setSubOpen] = useState(-1)
  const hasSpecialLinks = menuItem?.children.find(subItem => subItem.special)
  const { getNestedTranslation: t } = useI18n()

  return (
    <SubMenuContainer
      ml={{ default: "s", m: "m" }}
      mt={{ default: "xxs" }}
      mb={{ default: "s", m: "l" }}
    >
      <SubNavList>
        {menuItem?.children?.map((subItem, subIdx) => {
          return (
            !subItem.special && (
              <SubNavItem key={subIdx}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb="xxs"
                  opacity={isActiveLink(subItem) ? 0.5 : 1}
                >
                  {subItem?.url ? (
                    <SubLinkWrapper
                      href={subItem?.url?.href}
                      linkToURL={subItem?.url?.linkToURL}
                      newTab={subItem?.url?.newTab}
                      noFollow={subItem?.url?.noFollow}
                    >
                      <SubLinkItem textStyle="headings" color="text01">
                        {subItem.label}
                      </SubLinkItem>
                    </SubLinkWrapper>
                  ) : (
                    <SubLinkItem
                      textStyle="headings"
                      color="text01"
                      onClick={() => setSubOpen(subIdx)}
                      data-testid="submenuLabel"
                    >
                      {subItem.label}
                    </SubLinkItem>
                  )}
                  {subItem?.children?.length > 0 && (
                    <Box height="24px">
                      <Icons
                        display={{ default: "inline", xl: "none" }}
                        name="higher"
                        fill="text01"
                        height="24px"
                        cursor="pointer"
                        onClick={() => setSubOpen(subIdx)}
                        data-testid="goToSubmenu"
                        aria-hidden={true}
                      />
                    </Box>
                  )}
                </Box>
                {subItem?.auxText && (
                  <AuxText>
                    <Text textStyle="tinym" color="text01">
                      {subItem.auxText}
                    </Text>
                  </AuxText>
                )}

                <AnimatePresence>
                  {subItem?.children?.length && subOpen === subIdx && (
                    <Portal selector="#submenu">
                      <SubMenuWrapper
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                          open: {
                            x: 0,
                            transition: {
                              duration: 0.5,
                            },
                          },
                          closed: {
                            x: "100%",
                            transition: {
                              duration: 0.5,
                            },
                          },
                        }}
                      >
                        {/* Back button */}
                        <BackToMain my={{ default: "xl" }}>
                          <Flex
                            alignItems="center"
                            onClick={() => setSubOpen(-1)}
                            css={`
                              cursor: pointer;
                              transition: 0.3s ease;
                              &:hover {
                                opacity: 0.7;
                              }
                            `}
                          >
                            <Icons
                              width={{ default: "16px" }}
                              name="less"
                              fill="text01"
                              mr="4px"
                              aria-hidden={true}
                            />
                            <Text color="text01" textStyle="heading2xs">
                              {t("back", "Back")}
                            </Text>
                          </Flex>
                        </BackToMain>

                        {/* SubSubMenu */}
                        <Box minHeight="calc(100% - 261px)">
                          <Box
                            css={`
                              &::after {
                                content: "";
                                line-height: 0;
                                display: block;
                                height: 1px;
                                width: 100%;
                                background-color: ${theme("colors.text01")};
                                margin: 24px 0;
                              }
                            `}
                          >
                            <SubItemLinkWrapper
                              to={subItem?.url?.linkToURL}
                              href={subItem?.url?.href}
                              active={isActiveLink(subItem)}
                              {...subItem?.url}
                            >
                              <Text textStyle="headings" color="text01">
                                {subItem.label}
                              </Text>
                            </SubItemLinkWrapper>
                          </Box>

                          <Box as="ul">
                            {subItem?.children?.map((subSubItem, thirdIdx) => (
                              <Box
                                as="li"
                                key={thirdIdx}
                                mb={{ default: "xs" }}
                              >
                                <LinkWrapper
                                  to={subSubItem?.url?.linkToURL}
                                  href={subSubItem?.url?.href}
                                  {...subSubItem?.url}
                                  css={`
                                    cursor: pointer;
                                    transition: 0.3s ease;
                                    &:hover {
                                      opacity: 0.7;
                                    }
                                    opacity: ${isActiveLink(subSubItem)
                                      ? 0.5
                                      : 1};
                                  `}
                                >
                                  <Text color="text01" textStyle="bodyLead">
                                    {subSubItem.label}
                                  </Text>
                                </LinkWrapper>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Flex
                          justifyContent="flex-end"
                          borderTop="1px solid #fff"
                          mt="m"
                          mb="l"
                        >
                          <LinkWrapper
                            to={menuItem?.url?.linkToURL}
                            href={menuItem?.url?.href}
                            {...menuItem?.url}
                            css={`
                              cursor: pointer;
                              display: flex;
                              justify-content: center;
                              margin-top: ${theme("spacing.s")};
                              transition: 0.3s ease;
                              &:hover {
                                opacity: 0.7;
                              }
                            `}
                          >
                            {menuItem?.url?.linkToURL || menuItem?.url?.href ? (
                              <>
                                <Text color="text01" textStyle="heading2xs">
                                  {menuItem.label}
                                </Text>

                                <Icons
                                  width={{ default: "16px" }}
                                  name="higher"
                                  fill="text01"
                                  ml="4px"
                                  mr="-5px"
                                  aria-hidden={true}
                                />
                              </>
                            ) : (
                              <Text color="text01" textStyle="navigation">
                                &nbsp;
                              </Text>
                            )}
                          </LinkWrapper>
                        </Flex>
                      </SubMenuWrapper>
                    </Portal>
                  )}
                </AnimatePresence>
              </SubNavItem>
            )
          )
        })}
      </SubNavList>
      {hasSpecialLinks && (
        <SubNavList style={{ marginTop: "24px" }}>
          {menuItem?.children?.map((subItem, subIdx) => {
            return (
              subItem.special && (
                <SubNavItem key={subIdx}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="xxs"
                    opacity={isActiveLink(subItem) ? 0.5 : 1}
                  >
                    <SubLinkWrapper
                      {...subItem?.url}
                      to={subItem?.url?.linkToURL}
                      href={subItem?.url?.href}
                      {...subItem?.url}
                      style={{}}
                    >
                      <Icons
                        name="question"
                        height="21px"
                        width="21px"
                        fill="white"
                        aria-hidden={true}
                      />
                      <Text textStyle="heading3xs" color="text01" ml="xxs">
                        {subItem.label}
                      </Text>
                    </SubLinkWrapper>
                  </Box>
                </SubNavItem>
              )
            )
          })}
        </SubNavList>
      )}
    </SubMenuContainer>
  )
}

export default SubMenu
