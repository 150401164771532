import React, { useState } from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { Box, Text } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import { LinkWrapper } from "@components"
import MobileSubMenu from "./MobileSubMenu"

const ContentWrapper = styled(Box)`
  overflow: hidden;
  height: auto;
  transition: ${p =>
    !p.isOpen
      ? "max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease"
      : "max-height 0.5s cubic-bezier(1, 0, 1, 0), opacity 1.5s ease"};
  max-height: ${p => (!p.isOpen ? 0 : "9999px")};
  opacity: ${p => (!p.isOpen ? 0 : "1")};
`

const LinkItem = styled(Text)`
  cursor: pointer;
  ${p =>
    p.active &&
    css`
      opacity: 0.5;
    `}
`

const Line = styled.div`
  line-height: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${theme("colors.text01")};
  margin: 24px 0;
  ${theme("mq.xl")} {
    display: none;
  }
`

const MobileMenuItem = ({ item, itemVariants, active, isActiveLink }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <motion.li variants={itemVariants}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {item?.url ? (
            <LinkWrapper
              css={`
                transition: 0.3s ease;
                &:hover {
                  opacity: 0.7;
                }
              `}
              title={item?.url?.newTab && item.label}
              href={item?.url?.href}
              linkToURL={item?.url?.linkToURL}
              newTab={item?.url?.newTab}
              noFollow={item?.url?.noFollow}
            >
              <LinkItem textStyle="headings" color="link01" active={active}>
                {item.label}
              </LinkItem>
            </LinkWrapper>
          ) : (
            <LinkItem
              textStyle="headings"
              color="link01"
              active={active}
              onClick={() => setOpen(!isOpen)}
              data-testid="linkItem"
            >
              {item.label}
            </LinkItem>
          )}
          {item?.children?.length ? (
            <Box height={{ default: "16px", m: "24px" }}>
              <Icons
                display={{ default: "block", xl: "none" }}
                name={isOpen ? "remove" : "add1"}
                fill="text01"
                height={{ default: "16px", m: "24px" }}
                cursor="pointer"
                onClick={() => setOpen(!isOpen)}
                data-testid="menuItem"
                aria-hidden={true}
              />
            </Box>
          ) : null}
        </Box>
        <Line />
      </motion.li>

      {/* Sub menu */}
      {item?.children?.length ? (
        <ContentWrapper as="li" isOpen={isOpen}>
          <MobileSubMenu menuItem={item} isActiveLink={isActiveLink} />
        </ContentWrapper>
      ) : null}
    </>
  )
}

export default MobileMenuItem
