import React from "react"
import styled, { css } from "styled-components"
import { LinkWrapper } from "@components"
import { apiComponentIsEmpty } from "@helpers"
import { Flex, Inline, Stack, Text, Box } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import { motion } from "framer-motion"
import { space } from "styled-system"
import MobileMenuItem from "./MobileMenuItem"
import RecursiveMenu from "./RecursiveMenu"
import HeaderCTAContainer from "./HeaderCTAContainer"
import { usePage, useI18n } from "@hooks"

const LinkItem = styled(Text)`
  cursor: pointer;
  &:focus {
    outline-offset: -2px;
  }
  ${p =>
    p.active &&
    css`
      opacity: 0.5;
    `}

  ${theme("mq.xl")} {
    opacity: 1;
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: ${p =>
        !p.menuBg
          ? p.unPin
            ? p.theme.colors.inverse02
            : p.theme.colors.inverse01
          : p.unPin
          ? p.theme.colors.inverse02
          : p.theme.colors.inverse02};
      margin-top: 2px;
      transition: 0.4s ease;
      transform: scale(0);
      transform-origin: 50%;
    }
  }
`

const Nav = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  background-color: ${theme("colors.ui01")};
  height: 100vh;
  width: 100%;
  z-index: 9;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 560px;
  padding: ${theme("spacing.l")} ${theme("spacing.xs")} ${theme("spacing.xxxl")};

  ${theme("mq.m")} {
    padding: ${theme("spacing.xxl")} ${theme("spacing.xxl")}
      ${theme("spacing.xxxl")};
  }

  ${theme("mq.xl")} {
    overflow: hidden;
    align-items: center;
    background-color: inherit;
    padding: 0;
    height: auto;
    max-width: 100%;
    margin-left: auto;
  }

  ${space}
`

const DesktopNavList = styled.ul`
  display: none;
  ${theme("mq.xl")} {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
`

const MobileNavList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  ${theme("mq.xl")} {
    display: none;
  }
`

const NavItem = styled.li`
  ${theme("mq.xl")} {
    &:hover {
      ${LinkItem} {
        &:after {
          transform: scale(1);
        }
      }
    }
    &:focus {
      ${LinkItem} {
        &:after {
          transform: scale(1);
        }
      }
    }
    &:last-child {
      span {
        padding-right: 0;
      }
    }
  }
`

const NavContainer = ({
  socials,
  mainMenu,
  variant,
  unPin,
  listVariants,
  itemVariants,
  navVariants,
  externalLinks,
  searchLink,
  primaryLink,
  secondaryLink,
  customSetMenuBg,
  menuBg,
  pageLanguages,
  languageNames,
  languageId,
  // isActiveLink,
}) => {
  const links = [primaryLink, secondaryLink]
  const hasNoLinks =
    !apiComponentIsEmpty(primaryLink) || !apiComponentIsEmpty(primaryLink)

  const { fullUrl } = usePage()
  // Language
  const { getNestedTranslation: t } = useI18n()
  const open = t("icons.ariaLabel.open", "Open")
  const goToMain = t("initial.goToMain", "Go to main content")

  const isActiveLink = link => {
    const linkUrl = link?.url?.linkToURL
    const linkAnchor = link?.url?.anchor
    const children = link?.children
    let result = false

    if (typeof window !== "undefined" && window.location.hash) {
      result =
        `#${linkAnchor}` === window.location.hash &&
        linkUrl.replace(window.location.hash, "") === fullUrl
    } else {
      result = linkUrl === fullUrl
    }

    if (!result && children?.length) {
      result = children.some(child => isActiveLink(child))
    }
    return result
  }

  return (
    <Nav variants={navVariants}>
      <a
        href="#maincontent"
        css={`
          position: absolute;
          left: -9999px;
          top: auto;
          width: 1px;
          height: 1px;
          overflow: hidden;
        `}
      >
        {goToMain}
      </a>
      {hasNoLinks && (
        <HeaderCTAContainer
          hs={{ default: 0, m: "s" }}
          display={{ default: "flex", m: "none" }}
          links={links}
          menuBg={menuBg}
        />
      )}

      {hasNoLinks && (
        <HeaderCTAContainer
          hs={{ default: 0, m: "xs" }}
          display={{ default: "none", m: "flex" }}
          links={links}
          variant={variant}
          menuBg={menuBg}
          unPin={unPin}
        />
      )}

      {/* Desktop Menu */}
      <DesktopNavList>
        {mainMenu?.elements &&
          mainMenu.elements.map((item, idx) => {
            const linkItem = (
              <LinkItem
                menuBg={menuBg?.open}
                px={{ defautl: 0, xl: "xs" }}
                active={isActiveLink(item)}
                onKeyDown={event => event.key === "Enter" && isActiveLink(item)}
                textStyle="tiny2xl"
                unPin={unPin}
                color={{
                  default: "link01",
                  xl:
                    variant === "transparent" && !menuBg?.open
                      ? unPin
                        ? "text01"
                        : "inverse01"
                      : "text01",
                }}
              >
                {item.label}
              </LinkItem>
            )

            return (
              <NavItem
                key={idx}
                variants={itemVariants}
                onMouseEnter={() => customSetMenuBg(true, idx)}
                onKeyDown={event => {
                  if (event.key === "ArrowLeft") {
                    customSetMenuBg(true, idx)
                  }
                  if (event.key === "ArrowRight") {
                    customSetMenuBg(false, idx)
                  }
                }}
              >
                {item.url === null ? (
                  <Flex justifyContent="space-between" alignItems="center">
                    {linkItem}
                  </Flex>
                ) : (
                  <LinkWrapper
                    title={item?.label}
                    anchor={item?.url?.anchor}
                    href={item?.url?.href}
                    linkToURL={item?.url?.linkToURL}
                    newTab={item?.url?.newTab}
                    noFollow={item?.url?.noFollow}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {linkItem}
                  </LinkWrapper>
                )}
                {/* Desktop sub menu */}
              </NavItem>
            )
          })}
      </DesktopNavList>
      {/* Mobile Menu */}
      <MobileNavList variants={listVariants}>
        {mainMenu?.elements &&
          mainMenu.elements.map((item, idx) => {
            return (
              <MobileMenuItem
                key={idx}
                item={item}
                active={isActiveLink(item)}
                isActiveLink={isActiveLink}
                itemVariants={itemVariants}
              />
            )
          })}
      </MobileNavList>
      <motion.div
        variants={{
          open: {
            x: 0,
            opacity: 1,
            transition: {
              delay: 1.2,
              y: { stiffness: 1000 },
            },
          },
          closed: {
            x: 300,
            opacity: 0,
            transition: {
              y: { stiffness: 1000 },
            },
          },
        }}
      >
        {/* Recursive Menu */}
        {/* External Links */}
        <Flex mt={{ default: "xs", xl: 0 }} mb={{ defalut: "l", xl: 0 }}>
          {(externalLinks || searchLink) && (
            <RecursiveMenu
              externalLinks={externalLinks}
              searchLink={searchLink}
            />
          )}
        </Flex>
        {/* Icons */}
        <Flex
          alignItems="center"
          display={{ default: "flex", xl: "none" }}
          mb={{ defalut: "20px" }}
        >
          <Inline hs="s" flexShrink="0" mr="l">
            {socials &&
              socials.map((link, idx) => {
                return (
                  <LinkWrapper
                    key={idx}
                    display="flex"
                    href={link.href || link.url}
                    title={link.description}
                    {...link?.url}
                    css={`
                      transition: all 0.2s ease;
                      &:hover {
                        opacity: 0.5;
                        transform: translateY(-2px);
                      }
                    `}
                    newTab
                  >
                    <Icons
                      name={link.icon}
                      title={link.icon}
                      fill="text01"
                      height="24px"
                      role="img"
                      aria-label={open + " " + link.icon}
                    />
                  </LinkWrapper>
                )
              })}
          </Inline>
        </Flex>
        {/* Languages */}
        <Flex>
          {pageLanguages?.length > 1 && languageNames && (
            <Stack hs="xs" alignItems="center">
              {pageLanguages.map((lang, idx) => {
                const isOtherLanguage = languageId !== lang.languageId
                const LinkText = () => (
                  <Text
                    display="flex"
                    textStyle="tinyxs"
                    color="text01"
                    style={isOtherLanguage ? { fontWeight: "bold" } : {}}
                    css={`
                      &::before {
                        display: block;
                        content: ${idx > 0 ? '""' : "unset"};
                        float: left;
                        margin-right: 16px;
                        width: 1px;
                        height: 10px;
                        background-color: ${theme("colors.text03")};
                        align-self: center;
                      }
                    `}
                  >
                    {languageNames[lang?.locale]}
                  </Text>
                )
                return isOtherLanguage ? (
                  <LinkWrapper
                    key={idx}
                    href={lang.url}
                    css={`
                      &:hover {
                        opacity: 0.75;
                      }
                    `}
                  >
                    <LinkText />
                  </LinkWrapper>
                ) : (
                  <LinkText key={idx} />
                )
              })}
            </Stack>
          )}
        </Flex>
      </motion.div>
    </Nav>
  )
}
export default NavContainer
